import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="text-area-submit-on-enter"
export default class extends Controller {
  connect() {
    this.element.addEventListener('keydown', this.handleKeyDown.bind(this));
  }

  handleKeyDown(event) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.element.form.requestSubmit();
    }
  }
}
