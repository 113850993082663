import {Controller} from "@hotwired/stimulus"

// This is a click visibility controller
export default class extends Controller {
    static values = {
        "show": String, // id of the element to show
        "hide": String, // id of the element to hide
        "focus": String, // id of the element to focus; or parent_id/child selector
        "toggle": String, // id of the element to toggle visibility
        "follow": Boolean // default false, if true allows event to bubble up to parent
    }

    connect() {
        this.element.addEventListener("click", this.updateVisibility.bind(this));
    }

    disconnect() {
        this.element.removeEventListener("click", this.updateVisibility.bind(this));
    }

    updateVisibility(event) {
        if (this.showValue) {
            const showElement = this.getElementById(this.showValue);
            if (showElement) {
                showElement.classList.remove('hidden');
                this.dispatch('showed', {target: showElement});
                if (!this.followValue) event.preventDefault();
            } else {
                console.error(`show element not found: ${this.showValue}`);
            }
        }

        if (this.hideValue) {
            const hideElement = this.getElementById(this.hideValue);
            if (hideElement) {
                hideElement.classList.add('hidden');
                this.dispatch('hidden', {target: hideElement});
                if (!this.followValue) event.preventDefault();
            } else {
                console.error(`hide element not found: ${this.hideValue}`);
            }
        }

        if (this.focusValue) {
            // we might sometimes want to specify a sub element like a form
            // for example 'parent/child' where parent is the id of the parent
            const focusElement = this.getElementByIdAllowingParents(this.focusValue);

            if (focusElement) {
                // give the button that was just pressed time to release
                setTimeout(() => {
                    focusElement.focus();
                    if (focusElement.tagName === 'INPUT') {
                        focusElement.setSelectionRange(focusElement.value.length, focusElement.value.length);
                    }
                    this.dispatch('focused', {target: focusElement});
                }, 100);
            } else {
                console.error(`focus element not found: ${this.focusValue}`);
            }
        }

        if (this.toggleValue) {
            // maybe multiple elements separated by spaces
            const toggleElements = this.toggleValue.split(' ').map(id => this.getElementById(id));
            toggleElements.forEach(toggleElement => {
                if (toggleElement) {
                    toggleElement.classList.toggle('hidden');
                    this.dispatch('toggled', {target: toggleElement});
                    if (!this.followValue) event.preventDefault();
                } else {
                    console.error(`toggle element not found: ${toggleElement}`);
                }
            });
        }
    }

    getElementByIdAllowingParents(id) {
        if (id.includes('/')) {
            const [parent, child] = id.split('/');
            return this.getElementById(parent).querySelector(child);
        }

        return this.getElementById(id);
    }

    getElementById(id) {
        if (this.element.id === id) {
            return this.element;
        }

        const childElement = this.element.querySelector(`#${id}`);
        if (childElement) {
            return childElement;
        }

        return document.getElementById(id);
    }
}
