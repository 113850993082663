import {Controller} from "@hotwired/stimulus"

// https://docs.shepherdpro.com/guides/usage/
import Shepherd from 'shepherd.js';

export default class extends Controller {
    static values = {
        electron: Boolean
    }

    connect() {
        // Check if the tour has already been completed
        if (localStorage.getItem('timersTourCompleted') !== 'true') {
            this.initializeTour();
            this.tour.start();
        }
    }

    initializeTour() {
        this.tour = new Shepherd.Tour({
            useModalOverlay: true,
            defaultStepOptions: {
                classes: 'bg-white shadow-md rounded-md',
                scrollTo: {behavior: 'smooth', block: 'center'},
                arrow: true,
            }
        });

        const commonButtons = {
            buttons: [
                {text: 'Back', action: this.tour.back},
                {text: 'Next', action: this.tour.next}
            ]
        }

        const finalButtons = {
            buttons: [
                {text: 'Back', action: this.tour.back},
                {text: 'Got it!', action: this.tour.complete}
            ]
        }

        const steps = [
            {
                id: 'welcome',
                text: `<div class="max-w-lg">
                <h3 class="text-lg font-semibold mb-2">Welcome to MakeTimeFlow Focus!</h3>
                <p>We're excited to help you regain control of your time and attention.
                Our system is designed to help you overcome distractions and reclaim your time and attention.</p>
                </div>`,
                buttons: [
                    {text: "Let's begin!", action: this.tour.next},
                    // { text: 'Skip for now', action: this.tour.cancel },
                    // { text: 'No thanks', action: this.tour.complete }
                ]
            },
            {
                id: 'timer-input',
                text: `<div class="max-w-lg">
                <p>Start your intentional work session by:</p>
                <ol class="list-decimal pl-4 space-y-2">
                <li>Entering how many minutes you want to focus for</li>
                <li>Declaring what you are working on</li>
                <li>Clicking start to begin your focus time</li>
                </ol>
                </div>`,
                attachTo: {
                    element: ".new-timer-graph",
                    on: "bottom"
                },
                ...commonButtons
            },
            {
                id: 'quick-timers',
                text: `<div class="max-w-lg">
                <p>Or use one of our quick timers:</p>
                <ul class="list-disc pl-4 space-y-2">
                <li><span class="font-medium">5-10 minutes</span> for potentially distracting tasks like email or browsing</span></li>
                <li><span class="font-medium">25 minutes</span> for quick focus between meetings</li>
                <li><span class="font-medium">45 minutes or more</span> for deep work</li>
                </ul>
                </div>`,
                attachTo: {
                    element: '#quick-timer-buttons',
                    on: 'top'
                },
                ...commonButtons
            },
            {
                id: 'task-list',
                text: `<div class="max-w-lg">
                <h3 class="text-lg font-semibold mb-2">Task Trust System</h3>
                <p>Build trust with yourself by:</p>
                <ul class="list-disc pl-4 space-y-2">
                <li>Capturing tasks as they come to mind</li>
                <li>Estimating how long you want to spend on them</li>
                <li>Using the "Start" button to begin an intentional timer</li>
                </ul>
                </div>`,
                attachTo: {
                    element: "#tasks-list",
                    on: "top"
                },
                ...commonButtons
            },
            {
                id: "add-task",
                text: `<div class="max-w-lg">
                <p>Quickly add new tasks using:</p>
                <ul class="list-disc pl-4 space-y-2">
                <li>The + button</li>
                 <li>Keyboard shortcut: <kbd class="px-2 py-1 bg-gray-100 rounded">Cmd/Ctrl + K</kbd></li>
                </ul>
                </div>`,
                attachTo: {
                    element: "#add-task",
                    on: "bottom"
                },
                ...commonButtons
            },
            {
                id: "rituals",
                text: `<div class="max-w-lg">
                <h3 class="text-lg font-semibold mb-2">Rituals</h3>
                <p>Access our proven daily rituals anytime here to help you:</p>
                <ul class="list-disc pl-4 space-y-2">
                    <li>Start your day well</li>
                    <li>Maintain focus and reprioritize effectively</li>
                    <li>End your day with a great shutdown ritual</li>
                </ul>
                </div>`,
                attachTo: {
                    element: this.electronValue ? "#begin-well-electron-menu" : "#begin-well-main-menu",
                    on: "bottom"
                },
                ...commonButtons
            },
            {
                id: "help",
                text: `<div class="max-w-lg">
                <h3 class="text-lg font-semibold mb-2">We're here to help!</h3>
                <p>Press <kbd class="px-2 py-1 bg-gray-100 rounded">F1</kbd> anytime to:</p>
                <ul class="list-disc pl-4 space-y-2">
                  <li>Access our <a href="https://help.maketimeflow.app" target="_blank">help documentation</a></li>
                  <li>Join our <a href="https://community.maketimeflow.com" target="_blank">community forum</a></li>
                  <li>Connect with <a href="mailto:friendlyperson@maketimeflow.com">our support team</a></li>
                </ul>            
                </div>`,
                ...finalButtons
          }
        ]

        steps.forEach(step => this.tour.addStep(step));

        this.tour.on('complete', () => {
            localStorage.setItem('timersTourCompleted', 'true');
        })
    }

    disconnect() {
        if (this.tour) {
            this.tour.cancel();
        }
    }

    startTour() {
        if (!this.tour) {
            this.initializeTour();
        }
        this.tour.start()
    }
}