import {Controller} from "@hotwired/stimulus"

// This controller works with Steppable controllers to allow users to update the next step
// The next step is usually defined as a hidden_field_tag in the form
// we update this hidden field with the next step value
// and request submission of the form
export default class extends Controller {
    static values = {
        goto: String
    }

    connect() {
        this.element.addEventListener("click", this.gotoNextStep.bind(this))
    }

    gotoNextStep(event) {
        event.preventDefault()

        const stepHiddenField = document.querySelector('input[name="step"]')
        if (stepHiddenField) {
            stepHiddenField.value = this.gotoValue
            stepHiddenField.form.submit()
        } else {
            console.warn("No step hidden field found")
        }
    }
}
