import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="input-clear"
export default class extends Controller {
  static values = {
    target: String
  }
  connect() {
    this.element.addEventListener("click", this.click.bind(this))
  }

  disconnect() {
    this.element.removeEventListener("click", this.clear.bind(this))
  }

  click(event) {
    event.preventDefault()
    this.clear()
  }

  clear() {
    const target = this.targetValue
    const input = document.querySelector(target)
    if (input) {
      input.value = ""
    }
  }
}
