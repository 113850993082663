import {Controller} from "@hotwired/stimulus"

// This controller helps to keep the chat window either scrolled to a message
// or to an anchor element
// scrollTo: top (default) or bottom

// TODO: this works when we display the messages all at once
// but it does not work when we are adding dynamically to the chat

export default class extends Controller {
    static targets = ["message", "anchor"]
    static values = {
        scrollTo: { type: String, default: "top" }
    }

    messageTargetConnected(target) {
        console.log("ChatController.messageTargetConnected", target)
        this.scrollTo(target)
    }

    scrollTo(target) {
        console.log("scrollTo")
        setTimeout(() => {
            if (this.scrollToValue === "top") {
                this.scrollToTop(target)
            } else {
                this.scrollToBottom(target)
            }
        }, 500)
    }

    scrollToBottom(target) {
        if (this.hasAnchorTarget) {
            this.anchorTarget.scrollIntoView({behavior: "smooth"})
        } else {
            target.scrollIntoView({behavior: "smooth"})
        }
    }

    scrollToTop(target) {
        if (this.hasAnchorTarget) {
            this.anchorTarget.scrollIntoView({behavior: "smooth", block: "start"})
        } else {
            target.scrollIntoView({behavior: "smooth", block: "start"})
        }
    }
}