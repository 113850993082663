import { Controller } from '@hotwired/stimulus'

// See also text_area_autogrow (from Stimulus components)
export default class extends Controller {
    connect() {
        this.setCursorToEnd()
    }

    setCursorToEndFor() {
        if (this.element.tagName !== 'TEXTAREA') {
            var textArea = this.element;
            if (textArea) {
                var len = textArea.value.length;
                textArea.focus();
                textArea.setSelectionRange(len, len);
            }
        } else {
            console.error('This controller can only be used with textarea elements');
        }
    }
}