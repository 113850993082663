// Customizable command palette for advanced users
// Opens with cmd+k or ctrl+k by default
// https://github.com/excid3/ninja-keys

import {Controller} from "@hotwired/stimulus"
import "@gorails/ninja-keys"

export default class extends Controller {
    static values = {
        areas: Array,
        projects: Array,
        searchPath: {type: String, default: "/tasks"},
        electron: Boolean
    }

    connect() {
        // const isMac = /Mac|iPod|iPhone|iPad/.test(navigator.platform)
        const modKey = this.electronValue ? "cmd" : "ctrl"
        this.setDefaultData(modKey)
        this.enableNinjaKeysInEditFields()
        this.setupFallbackSearch(modKey)
    }

    setDefaultData(modKey) {
        this.element.data = [
            ...this.navigationCommands(modKey),
            ...this.taskFilterCommands(modKey),
            ...this.quickTimerCommands(modKey),
            ...this.userCommands(modKey),
            ...this.areaCommands(modKey),
            ...this.projectCommands(modKey)
        ]
    }

    setupFallbackSearch(modKey) {
        this.element.addEventListener('change', (event) => {
            const searchQuery = event.detail?.search?.trim()
            if (searchQuery && event.detail?.actions?.length === 0) {
                // Add a search action to the ninja UI
                this.element.data = [{
                    id: 'search',
                    title: ` Search for "${searchQuery}"`,
                    section: 'Search',
                    handler: () => {
                        window.location.href = `${this.searchPathValue}?search=${encodeURIComponent(searchQuery)}`
                    }
                }]
            } else {
                this.setDefaultData(modKey)
            }
        })
    }

    navigationCommands(modKey) {
        return [
            {
                id: "Focus",
                title: "Focus",
                hotkey: `${modKey}+1`,
                icon: this.homeIcon(),
                handler: () => {
                    Turbo.visit("/timers/new")
                }
            },

            {
                id: "Add",
                title: "Add",
                hotkey: `${modKey}+shift+A`,
                handler: () => {
                    // if there is an element on the current page with id add-task, click that
                    const addTask = document.getElementById("add-task")
                    if (addTask) {
                        addTask.click()
                    } else if (this.electronValue) {
                        window.electronAPI.openQuickAdd()
                    } else {
                        Turbo.visit("/quick_add/new")
                    }
                }
            },
            {
                id: "Tasks",
                title: "Tasks",
                hotkey: `${modKey}+T`,
                handler: () => {
                    Turbo.visit("/tasks")
                }
            },
            {
                id: "Timeblock",
                title: "Timeblock",
                hotkey: `${modKey}+shift+B`,
                handler: () => {
                    Turbo.visit("/tasks/blocks")
                }
            },
            {
                id: "Begin",
                title: "Begin",
                handler: () => {
                    if (this.electronValue) {
                        window.TurboNativeBridge.postMessage('begin-well')
                    } else {
                        Turbo.visit("begin_well_ritual")
                    }
                }
            },
            {
                id: "Shutdown",
                title: "Shutdown",
                handler: () => {
                    if (this.electronValue) {
                        window.TurboNativeBridge.postMessage('end-well')
                    } else {
                        Turbo.visit("shutdown_ritual")
                    }
                }
            },
            {
                id: "WRAP",
                title: "WRAP",
                handler: () => {
                    if (this.electronValue) {
                        window.TurboNativeBridge.postMessage('wrap')
                    } else {
                        Turbo.visit("weekly_planning_ritual")
                    }
                }
            },
            {
                id: "Tada List",
                title: "Tada List",
                hotkey: `${modKey}+shift+T`,
                handler: () => {
                    Turbo.visit("/journal_entries")
                }
            },
            {
                id: "Tada New",
                title: "Tada New",
                hotkey: `${modKey}+shift+N`,
                handler: () => {
                    Turbo.visit("/journal_entries/new")
                }
            },
            {
                id: "Text New",
                title: "Text New",
                hotkey: `${modKey}+N`,
                handler: () => {
                    Turbo.visit("/tasks_as_text/new")
                }
            },
            {
                id: "Text Edit",
                title: "Text Edit",
                hotkey: `${modKey}+E`,
                handler: () => {
                    Turbo.visit("/tasks_as_text/edit")
                }
            },
            {
                id: "Comms",
                title: "Comms",
                hotkey: `${modKey}+shift+C`,
                handler: () => {
                    Turbo.visit("/user_communication_tools")
                }
            },
            {
                id: "Goals",
                title: "Goals",
                handler: () => {
                    Turbo.visit("/user_goals")
                }
            },
            {
                id: "Snapshots",
                title: "Snapshots",
                handler: () => {
                    Turbo.visit("/tasks/snapshots")
                }
            },
            {
                id: "Find",
                title: "Find Tasks",
                hotkey: `${modKey}+F`,
                handler: () => {
                    if (this.electronValue) {
                        Turbo.visit("/tasks?search=")
                    } else {
                        const searchTerm = prompt("Enter search term:")
                        if (searchTerm) {
                            Turbo.visit(`/tasks?search=${encodeURIComponent(searchTerm)}`)
                        }
                    }
                }
            },
            {
                id: "Back",
                title: "Back",
                hotkey: `${modKey}+[`,
                handler: () => {
                    history.back()
                }
            },
            {
                id: "Forward",
                title: "Forward",
                hotkey: `${modKey}+]`,
                handler: () => {
                    history.forward()
                }
            },
            {
                id: "Help",
                title: "Help",
                hotkey: `f1`,
                handler: () => {
                    // show this in an external window
                    let docsUrl = "https://help.maketimeflow.app"
                    // if electron use openExternal
                    if (this.electronValue) {
                        window.electronAPI.openExternal(docsUrl)
                    } else {
                        window.open(docsUrl, "help")
                    }
                }
            }
        ]
    }

    taskFilterCommands(modKey) {
        return [
            {
                id: "Next",
                title: "Next",
                hotkey: `${modKey}+2`,
                handler: () => {
                    Turbo.visit("/tasks?search=@next")
                }
            },
            {
                id: "Later",
                title: "Later",
                hotkey: `${modKey}+3`,
                handler: () => {
                    Turbo.visit("/tasks?search=@later")
                }
            },
            {
                id: "Inbox",
                title: "Inbox",
                hotkey: `${modKey}+i`,
                handler: () => {
                    Turbo.visit("/tasks?search=@inbox")
                }
            },
            {
                id: "Calls",
                title: "Calls",
                hotkey: `${modKey}+4`,
                handler: () => {
                    Turbo.visit("/tasks?search=@call")
                }
            },
            {
                id: "Emails",
                title: "Emails",
                hotkey: `${modKey}+5`,
                handler: () => {
                    Turbo.visit("/tasks?search=@email")
                }
            },
            {
                id: "Completed Today",
                title: "Completed Today",
                hotkey: `${modKey}+8`,
                handler: () => {
                    Turbo.visit("/timers/new?mode=completed")
                }
            },
            {
                id: "Completed All",
                title: "Completed All",
                hotkey: `${modKey}+shift+8`,
                handler: () => {
                    Turbo.visit("/tasks?search=status%3Acompleted")
                }
            },
            {
                id: "Cancelled",
                title: "Cancelled",
                hotkey: `${modKey}+alt+shift+8`,
                handler: () => {
                    Turbo.visit("/tasks?search=status%3Acancelled")
                }
            },
            {
                id: "Reference",
                title: "Reference",
                hotkey: `${modKey}+9`,
                handler: () => {
                    Turbo.visit("/tasks?search=@reference")
                }
            },
            {
                id: "Repeating",
                title: "Repeating Tasks",
                handler: () => {
                    Turbo.visit("/user_repeating_tasks/edit")
                }
            },
            {
                id: "All Active",
                title: "All Active",
                hotkey: `${modKey}+0`,
                handler: () => {
                    Turbo.visit("/tasks")
                }
            },
            {
                id: "All",
                title: "All",
                hotkey: `${modKey}+shift+0`,
                handler: () => {
                    Turbo.visit("/tasks?search=@all")
                }
            },
            {
                id: "Quick Mode",
                title: "Quick Mode",
                hotkey: `${modKey}+shift+1`,
                handler: () => {
                    Turbo.visit("/timers/new?mode=quick")
                }
            }
        ]
    }


    // Quick timer section
    quickTimerCommands(modKey) {
        const timerChildren = [
            {
                id: "deep45",
                title: "Deep Work 45 min",
                parent: "Quick",
                handler: () => {
                    Turbo.visit("/timers/new?task=Deep%20Work&duration=45")
                }
            },
            {
                id: "break5",
                title: "Break 5 min",
                parent: "Quick",
                handler: () => {
                    Turbo.visit("/timers/new?task=Break&duration=5")
                }
            },
            {
                id: "break10",
                title: "Break 10 min",
                parent: "Quick",
                handler: () => {
                    Turbo.visit("/timers/new?task=Break&duration=10")
                }
            },
            {
                id: "email10",
                title: "Email 10 min",
                parent: "Quick",
                handler: () => {
                    Turbo.visit("/timers/new?task=Email&duration=10")
                }
            },
            {
                id: "social5",
                title: "Social Media 5 min",
                parent: "Quick",
                handler: () => {
                    Turbo.visit("/timers/new?task=Social%20Media&duration=5")
                }
            }
        ]

        return [
            {
                id: "Quick",
                title: "Quick Timers",
                hotkey: `${modKey}+shift+t`,
                icon: this.homeIcon(),
                children: timerChildren.map(child => child.id),
                handler: () => {
                    this.element.open({parent: 'Quick'})
                    return {keepOpen: true}
                }
            },
            ...timerChildren
        ]
    }

    userCommands(modKey) {
        return [
            {
                id: "Profile",
                title: "Profile",
                subtitle: "Edit your profile, name or password",
                icon: this.profileIcon(),
                handler: () => {
                    Turbo.visit("/users/edit")
                }
            },
            {
                id: "Notifications",
                title: "Notifications",
                icon: this.notificationIcon(),
                handler: () => {
                    Turbo.visit("/notifications")
                }
            },
            {
                id: "Settings",
                title: "Settings",
                hotkey: `${modKey}+,`,
                handler: () => {
                    if (this.electronValue) {
                        window.TurboNativeBridge.postMessage("settings")
                    } else {
                        Turbo.visit("/preferences")
                    }
                }
            }
        ]
    }

    areaCommands(modKey) {
        if (this.areasValue.length === 0) {
            return []
        }
        return this.areasValue.map(area => ({
            id: `area-${area.id}`,
            title: `Area: ${area.name}`,
            section: "Areas",
            handler: () => {
                Turbo.visit(area.path)
            }
        }))
    }

    projectCommands(modKey) {
        if (this.projectsValue.length === 0) {
            return []
        }
        return this.projectsValue.map(project => ({
            id: `project-${project.id}`,
            title: `Project: ${project.name}`,
            section: "Projects",
            subtitle: project.area_name ? `Area: ${project.area_name}` : null,
            handler: () => {
                Turbo.visit(project.path)
            }
        }))
    }

    enableNinjaKeysInEditFields() {
        const ninja = this.element
        document.querySelectorAll('input[type="text"], input[type="search"], textarea').forEach(input => {
            input.addEventListener('keydown', (event) => {
                if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === 'k') {
                    event.preventDefault()
                    ninja.open()
                }
                // enable all the defined hotkeys in this field
                this.element.data.forEach(item => {
                    if (item.hotkey) {
                        const hotkeyParts = item.hotkey.toLowerCase().split('+')
                        const key = hotkeyParts[hotkeyParts.length - 1]
                        const needsCmd = hotkeyParts.includes('cmd')
                        const needsShift = hotkeyParts.includes('shift')
                        const needsAlt = hotkeyParts.includes('alt')
                        const needsCtrl = hotkeyParts.includes('ctrl')

                        if (
                            event.key.toLowerCase() === key &&
                            (needsCmd === event.metaKey) &&
                            (needsCtrl === event.ctrlKey) &&
                            (needsShift === event.shiftKey)
                        ) {
                            event.preventDefault()
                            item.handler()
                        }
                    }
                })

            })
        })
    }

    homeIcon() {
        return '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" /></svg>'
    }

    profileIcon() {
        return '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>'
    }

    notificationIcon() {
        return '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" /></svg>'
    }
}
