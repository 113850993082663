import {Controller} from "@hotwired/stimulus"

const BUTTON_STATES = {
    processing: `
        <span class="inline-flex items-center">
            <svg class="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Processing...
        </span>
    `,
    success: `
        <span class="inline-flex items-center">
            <svg class="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
            </svg>
            Saved!
        </span>
    `,
    error: `
        <span class="inline-flex items-center text-red-500">
            <svg class="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
            <span class="text-white font-bold">Error</span>
        </span>
    `
}

const STATES = {
    READY: 'ready',
    PROCESSING: 'processing',
    SUCCESS: 'success',
    ERROR: 'error'
}

// if autoSave is true, the controller will save the content after 5 seconds
// Note: when dynamic content is rendering (such as chunked responses from an AI system)
// the controller can be created multiple times while the code block is rendering, so 
// the time at which it is automatically saved should not be too short
export default class extends Controller {
    static targets = ["content", "saveButton", "error"]
    static values = {
        type: String,
        url: String,
        blockId: String,
        formId: {type: String, default: "new_message_form"},
        state: {type: String, default: STATES.READY},
        autoSave: {type: Boolean, default: false}
    }

    connect() {
        this.connected = true
        this.setupTextArea()
        this.originalContent = this.contentTarget.value
        if (this.autoSaveValue) {
            setTimeout(() => {
                if (this.connected) this.save();
            }, 3000)
        }
    }

    disconnect() {
        this.connected = false
        this.contentTarget.removeEventListener("input", this.handleInput.bind(this))
        if (this.hiddenField) {
            this.hiddenField.remove()
        }
    }

    setState(newState) {
        this.stateValue = newState
        this.saveButtonTarget.disabled = newState === STATES.PROCESSING

        switch(newState) {
            case STATES.PROCESSING:
                this.saveButtonTarget.innerHTML = BUTTON_STATES.processing
                this.hideError()
                break
            case STATES.SUCCESS:
                this.saveButtonTarget.innerHTML = BUTTON_STATES.success
                this.hideError()
                break
            case STATES.ERROR:
                this.saveButtonTarget.innerHTML = BUTTON_STATES.error
                this.showError()
                break
            default:
                this.saveButtonTarget.innerHTML = "Save"
                // leave the error as is, success or processing will hide it if appropriate
        }
    }

    setError(message) {
        this.setState(STATES.ERROR)

        if (this.errorTarget) {
            this.errorTarget.textContent = message
            this.showError()
        } else {
            console.error("CodeBlockController.setError: ", message)
        }
    }

    showError() {
        if (this.errorTarget) {
            this.errorTarget.classList.remove("hidden")
        }
    }

    hideError() {
        if (this.errorTarget) this.errorTarget.classList.add("hidden");
    }

    setupTextArea() {
        this.contentTarget.addEventListener("input", this.handleInput.bind(this))
        this.autoExpand()
    }

    handleInput() {
        this.autoExpand()

        const hasChanged = this.contentTarget.value !== this.originalContent

        if (hasChanged) {
            if (this.stateValue === STATES.SUCCESS) {
                this.setState(STATES.READY)
            }

            if (this.hiddenField) {
                this.updateHiddenField()
            } else {
                this.createHiddenField()
            }
        } else {
            if (this.hiddenField) {
                this.hiddenField.remove()
                this.hiddenField = null
            }
        }
    }

    autoExpand() {
        const textarea = this.contentTarget
        textarea.style.height = "auto"
        textarea.style.height = textarea.scrollHeight + "px"
    }

    createHiddenField() {
        const form = document.getElementById(this.formIdValue)
        if (form) {
            this.hiddenField = document.createElement("input")
            this.hiddenField.type = "hidden"
            this.hiddenField.name = `message[attachments][${this.blockIdValue}]`
            this.hiddenField.value = this.contentTarget.value
            form.appendChild(this.hiddenField)
        } else {
            console.warn(`CodeBlocksController: No form found with id ${this.formIdValue}`)
        }
    }

    updateHiddenField() {
        if (this.hiddenField) {
            this.hiddenField.value = this.contentTarget.value
        }
    }

    async save() {
        if (this.stateValue === STATES.PROCESSING) return;

        this.setState(STATES.PROCESSING)

        try {
            const response = await fetch(this.urlValue, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
                },
                body: JSON.stringify({
                    content: this.contentTarget.value,
                    block_type: this.typeValue
                })
            })

            if (response.ok) {
                this.setState(STATES.SUCCESS)
            } else {
                console.error("Save failed", response)
                // read error message from response json body
                const responseBody = await response.json()
                console.error("Error saving code block", responseBody)
                this.setError(responseBody.error)
            }
        } catch (error) {
            console.error("Error saving code block", error)
            this.setError("Unable to save! ", error.message)
        } finally {
            setTimeout(() => {
                // keep saved, but reset all other states
                if (this.stateValue != STATES.SUCCESS) {
                    this.setState(STATES.READY)
                }
            }, 2000)
        }
    }
}
